import React from 'react';
import { motion } from 'framer-motion';
import Separator from '../components/Separator';
import './Home.css';
import Career from '../components/Career';
import Projects from '../components/Projects';
import Contacts from '../components/Contacts';


function Home() {
  return (
    <div>
      <section id="home" className="home-section h-screen bg-contain bg-center bg-no-repeat flex items-center justify-center" style={{ backgroundImage: `url('/assets/background2.jpg')` }}>
        <div className="text-center text-black px-4">
        <motion.div className="inline-block bg-white bg-opacity-35 p-4 rounded-lg"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0 }}
                    >
            {/* Headline with Framer Motion Animation */}
            <motion.h1
              className="text-4xl text-custom-black md:text-6xl font-bold mb-4 font-roboto"
              initial={{ opacity: 0, y: -200 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 2 }}
            >
              Hi, I'm Justinas Kalinauskas
            </motion.h1>

            {/* Subheadline */}
            <motion.p
              className="text-lg text-custom-black md:text-2xl mb-8 font-roboto"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
            A Full-Stack Developer with three years of experience designing, developing, and optimizing responsive web applications.
            </motion.p>
          

            {/* Profile Image with Framer Motion Animation */}
            <motion.img
              src="/assets/profile.jpeg"
              alt="Justinas Kalinauskas"
              className="w-40 h-40 md:w-60 md:h-60 rounded-full mx-auto mb-8 object-cover border-4 border-custom-black"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1, delay: 1 }}
            />

            {/* CTA Buttons */}
            <motion.div
              className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1.5 }}
            >
              <a
                href="projects"
                className="px-6 py-3 bg-custom-black rounded-full text-white font-roboto  hover:bg-white hover:text-custom-black transition"
              >
                View My Work
              </a>
              <a
                href="contacts"
                className="px-6 py-3 bg-custom-black rounded-full text-white font-roboto  hover:bg-white hover:text-custom-black transition"
              >
                Contact Me
              </a>
            </motion.div>

            {/* Social Media Icons (Optional) */}
            <motion.div
              className="flex justify-center space-x-4 mt-8"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 2 }}
            >
              <a href="https://www.linkedin.com/in/justinaskalinauskas" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400 transition">
                <svg xmlns="http://www.w3.org/2000/svg"  className="h-6 w-6" fill="#00000" viewBox="0 0 24 24">
                  <path d="M19 0h-14c-2.762 0-5 2.238-5 5v14c0 2.762 2.238 5 5 5h14c2.762 0 5-2.238 5-5v-14c0-2.762-2.238-5-5-5zm-11 19h-3v-9h3v9zm-1.5-10.3c-.966 0-1.75-.784-1.75-1.75s.784-1.75 1.75-1.75 1.75.784 1.75 1.75-.784 1.75-1.75 1.75zm13.5 10.3h-3v-4.5c0-1.078-.022-2.462-1.5-2.462-1.5 0-1.73 1.172-1.73 2.373v4.573h-3v-9h2.877v1.229h.041c.401-.758 1.379-1.557 2.838-1.557 3.037 0 3.6 2.0.6 4.1v4.531z"></path>
                </svg>
              </a>
              <a href="https://github.com/Jkali8" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400 transition">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="#00000" viewBox="0 0 24 24">
                  <path d="M12 0c-6.627 0-12 5.373-12 12a12.014 12.014 0 008.205 11.385c.6.111.82-.261.82-.577v-2.234c-3.338.725-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.744.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.809 1.304 3.495.997.108-.775.419-1.304.762-1.604-2.665-.305-5.466-1.332-5.466-5.931 0-1.31.469-2.381 1.235-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23a11.52 11.52 0 016 0c2.292-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.873.118 3.176.77.84 1.234 1.911 1.234 3.221 0 4.61-2.804 5.624-5.475 5.921.43.372.823 1.102.823 2.222v3.293c0 .317.218.694.825.576a12.014 12.014 0 008.205-11.385c0-6.627-5.373-12-12-12z"></path>
                </svg>
              </a>
              {/* Add more social icons as needed */}
            </motion.div>
          </motion.div>
        </div>
      </section>

      <motion.div 
                  initial={{ opacity: 0, y: -50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}>
        <Separator />
      </motion.div>
      
      {/* About me */}
      <section
        id="about"
        className="py-20"
        data-aos="fade-up"
      >
        <div className="max-w-4xl mx-auto px-4">
          {/* Section Heading */}
          <motion.h2
            className="text-3xl md:text-4xl font-bold text-center mb-8 text-custom-red"
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            About Me
          </motion.h2>

          {/* Section Content */}
          <motion.div
            className="flex flex-col md:flex-row items-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            viewport={{ once: true }}
          >
            {/* Profile Image */}
            <div className="md:w-1/3 mb-6 md:mb-0">
              <img
                src="/assets/profile1.jpeg"
                alt="Justinas Kalinauskas"
                className="w-48 h-48 md:w-64 md:h-64 rounded-full object-cover mx-auto border-4 border-custom-red"
                loading="lazy"
              />
            </div>

            {/* About Text */}
            <div className="md:w-2/3 md:pl-12">
              <p className="text-lg text-custom-red">
                As a Full-Stack Developer with three years of experience, I specialize in designing and building responsive web applications that enhance user experience and drive business success. Proficient in technologies such as JavaScript, React, Node.js, and Tailwind CSS, I thrive in collaborative environments and am committed to continuous learning and improvement.
              </p>
            </div>
          </motion.div>
        </div>
      </section>

      <motion.div 
                  initial={{ opacity: 0, y: -50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}>
        <Separator />
      </motion.div>

      <section
        id="career"
        className="py-20"
        data-aos="fade-up"
      >
           <Career>
           </Career>
      </section>
    
      <motion.div 
                  initial={{ opacity: 0, y: -50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}>
        <Separator />
      </motion.div>

      <section
        id="projects"
        className="py-20"
        data-aos="fade-up"
      >
        <Projects>
        </Projects>
      </section>
      
      <motion.div 
                  initial={{ opacity: 0, y: -50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}>
        <Separator />
      </motion.div>

      <section
        id="contacts"
        className="py-20"
        data-aos="fade-up"
      >
        <Contacts>
        
        </Contacts>
      </section>

      <motion.div 
                  initial={{ opacity: 0, y: -50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}>
        <Separator />
      </motion.div>

    </div>
      
  );
}

export default Home;