import React from 'react';
import careerData from '../data/careerData';
import { FaCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';

function Career() {
    return (
        <section id="career" className="py-20 bg-custom-black">
            <div className="max-w-4xl mx-auto px-4">
                {/* Section Heading */}
                <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-custom-red" >Career</h2>

                {/* Timeline Container */}
                <div className="relative max-w-5xl mx-auto px-4">
                    {/* Vertical Line */}
                    <div className="hidden md:block absolute left-1/2 transform -translate-x-1/2 h-full border-l-2 border-custom-red"></div>

                    {/* Timeline Items */}
                    <ul className="space-y-12">
                        {careerData.map((job, index) => {
                            const isLeft = index % 2 === 0;
                            return (
                                <li key={index} className="grid grid-cols-1 md:grid-cols-9 items-center">
                                    {/* Left Content */}
                                    {isLeft && (
                                        <div className="hidden md:block md:col-span-4 md:col-start-1 pr-8">
                                            <div className="bg-white bg-opacity-35 p-6  rounded-lg shadow">
                                                <span className="text-sm text-custom-black">{job.years}</span>
                                                <h3 className="text-xl font-semibold mt-1">{job.company}</h3>
                                                <h4 className="text-lg font-medium text-custom-blue">{job.position}</h4>
                                                <p className="mt-2 text-custom-black">{job.description}</p>
                                            </div>
                                        </div>
                                    )}
                                    {!isLeft && (
                                        <div className="invisible hidden md:block md:col-span-4 md:col-start-1 pr-8">
                                        </div>
                                    )}

                                    {/* Marker */}
                                    <div className="hidden md:flex justify-center md:col-span-1">
                                        <motion.div
                                            initial={{ opacity: 0, scale: 0 }}
                                            whileInView={{ opacity: 1, scale: 1 }}
                                            transition={{ duration: 0.5, delay: index * 0.2 }}
                                            viewport={{ once: true }}
                                            className="relative"
                                        >
                                            <FaCircle className="text-custom-red text-xl" aria-hidden="true" />
                                        </motion.div>
                                    </div>

                                    {/* Right Content */}
                                    {!isLeft && (
                                        <div className="hidden md:block md:col-span-4 md:col-start-6 pl-8">
                                            <div className="bg-white bg-opacity-35 p-6 rounded-lg shadow">
                                                <span className="text-sm text-custom-black">{job.years}</span>
                                                <h3 className="text-xl font-semibold mt-1">{job.company}</h3>
                                                <h4 className="text-lg font-medium text-custom-blue">{job.position}</h4>
                                                <p className="mt-2 text-custom-black">{job.description}</p>
                                            </div>
                                        </div>
                                    )}

                                    {/* Mobile Content */}
                                    <div className="md:hidden col-span-9">
                                        <div className="bg-white bg-opacity-35 p-6 rounded-lg shadow text-center">
                                            <span className="text-sm text-custom-black block">{job.years}</span>
                                            <h3 className="text-xl font-semibold mt-1 text-center">{job.company}</h3>
                                            <h4 className="text-lg font-medium text-custom-blue text-center">{job.position}</h4>
                                            <p className="mt-2 text-custom-black text-center">{job.description}</p>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Career;