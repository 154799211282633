
const careerData = [
    {
      years: '2024 - Present',
      company: 'Asseco Lithuania',
      position: ' Software Developer',
      description:
        'Developed and maintained enterprise applications using Java Spring Boot and Angular, implemented microservices architectures, and deployed microfrontends with Kubernetes.',
    },
    {
      years: '2023 - 2024',
      company: 'SEB',
      position: ' Software Developer',
      description:
        'Developed and deployed microfrontends with Java Spring Boot and Angular, and implemented CI/CD pipelines using Jenkins and Kubernetes to streamline code deployment.',
    },
    {
        years: '2022 - 2023',
        company: 'CGI',
        position: 'Junior Software Developer',
        description:
          'Developed scalable web applications using Vue.js Quasar Framework for the front end and Spring Boot for the back end. Managed PostgreSQL databases to ensure optimal data performance and optimized software architecture through object-oriented programming (OOP).',
      }
  ];
  
  export default careerData;