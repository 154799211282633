import React, { useState } from "react";
import "./Navbar.css";
import gsap from "gsap";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);

    if (!isMenuOpen) {
      // Animate the background text in when opening
      gsap.fromTo(
        ".name-overlay",
        { opacity: 0, scale: 0.8 },
        { opacity: 0.15, scale: 1, duration: 1, ease: "power1.out" }
      );
    } else {
      // Fade out the animation when closing
      gsap.to(".name-overlay", { opacity: 0, duration: 0.5 });
    }
  };

  const handleHover = (element, weight) => {
    gsap.to(element, {
      duration: 0.3,
      fontVariationSettings: `"wght" ${weight}`,
      ease: "power1.out",
    });
  };

  return (
    <>
      <button
        className="fixed top-4 right-4 z-50 bg-custom-black text-white p-4 rounded-full shadow-lg focus:outline-none"
        onClick={toggleMenu}
      >
        ☰
      </button>

      <div
        className={`fixed top-0 right-0 h-[70%] w-full bg-white text-white z-40 transition-transform duration-500 ${
          isMenuOpen ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        {/* Background overlay with your name */}
        <div className="name-overlay absolute inset-0 flex items-center justify-center text-[100px] font-bold text-custom-black opacity-0 pointer-events-none">
          Justinas Kalinauskas
        </div>

        {/* List items */}
        <ul className="absolute bottom-4 left-4 space-y-4">
          <li>
            <a
              href="#home"
              className="text-2xl text-custom-black"
              onMouseEnter={(e) => handleHover(e.target, 1200)}
              onMouseLeave={(e) => handleHover(e.target, 400)}
              onClick={toggleMenu}
            >
              Home
            </a>
          </li>
          <li>
            <a
              href="#about"
              className="text-2xl text-custom-black"
              onMouseEnter={(e) => handleHover(e.target, 1200)}
              onMouseLeave={(e) => handleHover(e.target, 400)}
              onClick={toggleMenu}
            >
              About Me
            </a>
          </li>
          <li>
            <a
              href="#projects"
              className="text-2xl text-custom-black"
              onMouseEnter={(e) => handleHover(e.target, 1200)}
              onMouseLeave={(e) => handleHover(e.target, 400)}
              onClick={toggleMenu}
            >
              Projects
            </a>
          </li>
          <li>
            <a
              href="#contacts"
              className="text-2xl text-custom-black"
              onMouseEnter={(e) => handleHover(e.target, 1200)}
              onMouseLeave={(e) => handleHover(e.target, 400)}
              onClick={toggleMenu}
            >
              Contacts
            </a>
          </li>
        </ul>

        {/* Contacts Div */}
        <div className="contacts-div absolute bottom-4 right-4 p-4 bg-custom-black text-white rounded-lg shadow-md">
          <p className="text-lg font-semibold">Contact Me</p>
          <p>Email: kalinauskas.justinas2000@gmail.com</p>
          <p>Phone: +37061020090</p>
        </div>
      </div>
    </>
  );
}

export default Navbar;
