import React from 'react';
import { FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';

function Contacts() {
  return (
    <section id="contacts" className="py-20 bg-custom-black">
      <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-custom-red">
        Contacts
      </h2>
      <div className="text-center">
        <p className="text-lg text-white mb-6">Feel free to connect with me:</p>

        {/* Contact Links as a List */}
        <ul className="space-y-4">
          {/* LinkedIn */}
          <li>
            <a
              href="https://www.linkedin.com/in/justinaskalinauskas"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center space-x-2 text-gray-300 hover:text-blue-500 transition duration-300"
            >
              <FaLinkedin className="text-2xl" />
              <span>LinkedIn</span>
            </a>
          </li>

          {/* GitHub */}
          <li>
            <a
              href="https://github.com/Jkali8"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center space-x-2 text-gray-300 hover:text-gray-500 transition duration-300"
            >
              <FaGithub className="text-2xl" />
              <span>GitHub</span>
            </a>
          </li>

          {/* Email */}
          <li>
            <a
              href="mailto:kalinauskas.justinas2000@gmail.com"
              className="flex items-center justify-center space-x-2 text-gray-300 hover:text-red-500 transition duration-300"
            >
              <FaEnvelope className="text-2xl" />
              <span>Email</span>
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Contacts;