import React from "react";

function Projects() {
  return (
    <section id="projects" className="py-20 bg-custom-black">
      <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-custom-red">
        Projects
      </h2>
      <div className="text-center">
        <p className="text-lg text-white mb-4">Exciting projects are on the way!</p>
        <p className="text-lg text-gray-300">Stay tuned for updates.</p>
      </div>
    </section>
  );
}

export default Projects;